import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { requestPermission, onMessageListener } from "../firebase/firebase"
import Footer from './Footer';
import Appbar from './AppBar';
import Chatbot from './Chatbot';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { withSwal } from 'react-sweetalert2';

function MakeAppt(props, ref) {
    // const [data, setData] = useState([]);
    const { swal, ...rest } = props;

    const [apt_user_name, setAptUserName] = useState('')
    const [apt_user_mobile, setAptUserMobile] = useState('')
    const [apt_user_email, setAptUserEmail] = useState('')
    const [apt_user_dob, setAptUserDob] = useState('')
    const [apt_user_tob, setAptUserTob] = useState('')
    const [apt_user_country, setAptUserCountry] = useState('')
    const [apt_user_state, setAptUserState] = useState('')
    const [apt_user_dist, setAptUserDist] = useState('')
    const [appt_user_village, setAptUserVillage] = useState('')
    const [apt_user_pob, setAptUserPob] = useState('')

    // const [isSubmitting, setIsSubmitting] = useState(false); // Add loading state
    const navigate = useNavigate();
    const [deviceToken, setDeviceToken] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [emailError, setEmailError] = useState(false)
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|online|org|net|edu|gov|info|io|co|in)$/;
        if (emailRegex.test(email)) {
            setAptUserEmail(email);
            setEmailError(false); // No error
            return true;  // Email

        } else {
            setEmailError(true); // Show error
            return false;  // Email

        }
    }
    useEffect(() => {
        const MAX_RETRIES = 15; // Maximum retries for fetching token
        let retryCount = 0; // Track retry attempts

        const fetchToken = () => {
            requestPermission()
                .then((token) => {
                    if (token) {
                        setDeviceToken(token); // Update state
                        localStorage.setItem("deviceToken", token); // Save to localStorage
                        console.log("FCM Token successfully retrieved:", token);
                    } else if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    } else {
                        console.error("Failed to fetch device token after retries.");
                    }
                })
                .catch((err) => {
                    console.error("Error retrieving FCM Token:", err);
                    if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    }
                });
        };

        // Initial token fetch
        fetchToken();

        // Listen for foreground messages
        onMessageListener()
            .then((payload) => {
                console.log("Message received in foreground:", payload);
                new Notification(payload.notification.title, {
                    body: payload.notification.body,
                });
            })
            .catch((err) => {
                console.error("Error listening to foreground messages:", err);
            });
    }, []);




    useEffect(() => {
        const placeOfBirth = `${appt_user_village || ''}, ${apt_user_dist || ''}, ${apt_user_state || ''}, ${apt_user_country || ''}`.replace(/(^, )|((, )+$)/g, ''); // Handle empty values
        setAptUserPob(placeOfBirth);
    }, [apt_user_country, apt_user_state, apt_user_dist, appt_user_village]);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        const isValidEmail = validateEmail(apt_user_email);
        if (!isValidEmail) {
            setIsSubmitting(false)
            return;
        }
        setIsSubmitting(true)
        const form = new FormData();

        form.append('apt_user_name', apt_user_name);
        form.append('apt_user_mobile', apt_user_mobile);
        form.append('apt_user_email', apt_user_email);
        form.append('apt_user_dob', apt_user_dob);
        form.append('apt_user_tob', apt_user_tob);
        form.append('apt_user_pob', apt_user_pob);
        form.append('user_device_token', deviceToken)


        console.log('Form Data to be submitted:', form); // Log the form data

        try {
            const response = await fetch('https://alpastrology.online/alpapi/User_api/userSignin', {
                method: 'POST',
                headers: {
                    'x-api-key': 'alp@123',
                },
                body: form,
            });

            const text = await response.text();
            console.log('Response text:', text);

            try {
                const responseData = JSON.parse(text);
                console.log('Form submission response:', responseData);

                if (responseData.status === "Success") {
                    // window.location.reload();
                    setAptUserCountry('');
                    setAptUserDist('');
                    setAptUserDob('');
                    setAptUserEmail('');
                    setAptUserMobile('');
                    setAptUserName('')
                    setAptUserVillage('');
                    setAptUserState('');
                    // alert('Registered Successfully');
                    // toast.success('Registered Successfully');
                    swal.fire({
                        title: 'Registered Successfully',
                        // text: 'Login Success',
                        icon: 'success',
                    });
                    setTimeout(() => {
                        navigate('/LoginScreen');
                    }, 3000); // 2-second delay
                } else {
                    alert('Error submitting form. Please try again.');
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                alert('Error parsing response. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again.');
        }
    };


    return (
        <>
            <Appbar />

            <div className=" ">
                <div className="app-content pt-3">
                    <div className="container-xl ">
                        <div className="wl_header text-center">
                            <h3 className='text-danger fw-bold my-2'>Registration Form </h3>
                            <hr />
                        </div>
                        <Row>
                            <Col lg={2} md={2} sm={12}></Col>
                            <Col lg={8} md={8} sm={12}>
                                <Form className="as_appointment_form" method="post" action="" onSubmit={handleSubmit}>
                                    {/* <ToastContainer /> */}
                                    <Row>
                                        <Col lg={12} md={12} sm={12} className="as_font14_subhead" data-aos="zoom-in-down" data-aos-duration="1100">
                                            <Row>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className="  as_font14">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="text" name="apt_user_name" placeholder="" className="form-control" required onChange={(e) => setAptUserName(e.target.value)} />
                                                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className="  as_font14">
                                                        <Form.Label>Mobile Number</Form.Label>
                                                        <PhoneInput defaultCountry='IN' international type="text" placeholder="" name="apt_user_mobile" required onChange={(value) => setAptUserMobile(value)} />
                                                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className="  as_font14">
                                                        <Form.Label>Date of Birth</Form.Label>
                                                        <Form.Control type="date" placeholder="Preferred Date" name="apt_user_dob" className="form-control" onChange={(e) => setAptUserDob(e.target.value)} required />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className="  as_font14">
                                                        <Form.Label>Time of Birth</Form.Label>
                                                        <Form.Control type="time" placeholder=" apt_time" name="apt_user_tob" className="form-control as_apt_timepicker" step={1} onChange={(e) => setAptUserTob(e.target.value)} />
                                                        <span><img src="assets/images/svg/clock.svg" alt="" required /></span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className="  as_font14">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="text" name="apt_user_email" placeholder="" className="form-control" onChange={(e) => setAptUserEmail(e.target.value)} />
                                                        <span><img src="assets/images/svg/pin.svg" alt="" required /></span>
                                                    </Form.Group>
                                                    {emailError && (
                                                        <small className='text-danger'>Please Enter the valid Email</small>
                                                    )}
                                                </Col>

                                            </Row>
                                            <Form.Group>
                                                <Form.Label>Place of Birth Details</Form.Label>
                                            </Form.Group>
                                            <Row>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className=" as_font14">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="apt_user_country"
                                                            placeholder=""
                                                            className="form-control"
                                                            onChange={(e) => setAptUserCountry(e.target.value)}
                                                            required
                                                        />
                                                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className=" as_font14">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="apt_user_state"
                                                            placeholder=""
                                                            className="form-control"
                                                            onChange={(e) => setAptUserState(e.target.value)}
                                                            required
                                                        />
                                                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className=" as_font14">
                                                        <Form.Label>District</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="apt_user_dist"
                                                            placeholder=""
                                                            className="form-control"
                                                            onChange={(e) => setAptUserDist(e.target.value)}
                                                            required
                                                        />
                                                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Group className=" as_font14">
                                                        <Form.Label>Town / Village</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="appt_user_village"
                                                            placeholder=""
                                                            className="form-control"
                                                            onChange={(e) => setAptUserVillage(e.target.value)}
                                                            required
                                                        />
                                                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Form.Group className=" as_font14" style={{ color: 'black' }}>
                                                <Form.Group className=" as_font14">
                                                    <Form.Control
                                                        type="hidden"
                                                        placeholder="Place of Birth"
                                                        name="apt_user_pob"
                                                        className="form-control"
                                                        value={apt_user_pob} // Dynamically updated value
                                                        readOnly
                                                    />
                                                    <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                                </Form.Group>
                                            </Form.Group>

                                        </Col>

                                        <Row>
                                            <Col lg={2} md={2} sm={12}></Col>
                                            <Col lg={8} className="text-center">
                                                <Button className="as_btn" type="submit" name="submit" disabled={isSubmitting}>Submit</Button>
                                            </Col>
                                            <Col lg={2} md={2} sm={12}></Col>

                                        </Row>
                                        <Row>
                                            <Col lg={3} md={3} sm={12}></Col>
                                            <Col lg={3} md={3} sm={12}>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} className='d-flex'>
                                                <p>If already Have an Account ?</p>
                                                <Button variant='info'><Link to='/LoginScreen' className='text-white'>Login</Link></Button>
                                            </Col>
                                            {/* <Button onClick={sendNotif}>Send</Button> */}

                                        </Row>
                                    </Row>
                                </Form>
                            </Col>
                            <Col lg={2} md={2} sm={12}></Col>
                        </Row>


                    </div>
                </div>
            </div>
            <Footer />
            <Chatbot />
        </>
    )
}

export default withSwal(MakeAppt)