import React, { useEffect, useState } from 'react';
import logo from '../assets/img/login_logo_mob.gif';
import '../assets/css/portal.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { requestPermission, onMessageListener } from "../firebase/firebase"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { withSwal } from 'react-sweetalert2';

function Login(props, ref) {
    const [userEmail, setuserEmail] = useState('');
    const [userMobile, setuserMobile] = useState('');
    const navigate = useNavigate();  // Corrected to call useNavigate()
    const [deviceToken, setDeviceToken] = useState(null);
    const { swal, ...rest } = props;
const[isSubmitting,setIsSubmitting]=useState(false)

    useEffect(() => {
        const MAX_RETRIES = 15; // Maximum retries for fetching token
        let retryCount = 0; // Track retry attempts

        const fetchToken = () => {
            requestPermission()
                .then((token) => {
                    if (token) {
                        setDeviceToken(token); // Update state
                        localStorage.setItem("deviceToken", token); // Save to localStorage
                        console.log("FCM Token successfully retrieved:", token);
                    } else if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    } else {
                        console.error("Failed to fetch device token after retries.");
                    }
                })
                .catch((err) => {
                    console.error("Error retrieving FCM Token:", err);
                    if (retryCount < MAX_RETRIES) {
                        retryCount += 1;
                        console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
                        setTimeout(fetchToken, 1000); // Retry after 1 second
                    }
                });
        };

        // Initial token fetch
        fetchToken();

        // Listen for foreground messages
        onMessageListener()
            .then((payload) => {
                console.log("Message received in foreground:", payload);
                new Notification(payload.notification.title, {
                    body: payload.notification.body,
                });
            })
            .catch((err) => {
                console.error("Error listening to foreground messages:", err);
            });
    }, []);

    useEffect(() => {
        const loggedIn = localStorage.getItem('loggedIn');
        if (loggedIn) {
            navigate('/Dashboard', { replace: true });
        }
    }, [navigate]);

    const handleLogin = (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        const formData = new FormData();
        formData.append('user_email', userEmail);
        formData.append('user_mob', userMobile);
        formData.append('user_device_token', deviceToken)

        axios.post(`https://alpastrology.online/alpapi/User_api/login_user`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123'
            }
        }).then((response) => {
            if (response.data.status === 'Success') {  // Check response.data.status
                console.log('Login Success');
                localStorage.setItem('loggedIn', true);
                localStorage.setItem('userId', JSON.parse(response.data.data.user_id));
                localStorage.setItem('userName', response.data.data.user_name);
                localStorage.setItem('userEmail', response.data.data.user_email);
                localStorage.setItem('userMobile', response.data.data.user_mob);
                localStorage.setItem('browseralert',0);
                swal.fire({
                    // title: 'Login',
                    text: 'Login Success',
                    icon: 'success',
                });
                setTimeout(() => {

                    navigate('/Dashboard', { replace: true });  // Fixed the typo
                },3000)
            } else {
                alert('Invalid Login');
            }
        }).catch((error) => {
            console.log('Login Error:', error);
        });
    };

    return (
        <div className="row g-0 app-auth-wrapper">

            <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5">
                <div className="d-flex flex-column align-content-end">

                    <div className="app-auth-body mx-auto">
                        <div className="app-auth-branding mb-4">
                            <a className="app-login-logo" href="index.html">
                                <img className="logo-icon me-2" src={logo} alt="logo" />
                            </a>
                            <h4 className="text-center" style={{ color: 'red' }}>Users Login</h4>
                        </div>
                        <h1 className="auth-heading text-center mb-5">User Login</h1>
                        <div className="auth-form-container text-start">
                            <form className="auth-form login-form" autoComplete='off' onSubmit={handleLogin}>
                                <div className="email mb-3">
                                    <label className="" htmlFor="signin-email">Email</label>
                                    <input
                                        id="signin-email"
                                        name="user_email"
                                        type="text"
                                        className="form-control signin-email log_input"
                                        placeholder=""
                                        required
                                        value={userEmail}
                                        onChange={(e) => setuserEmail(e.target.value)}
                                    />
                                </div>
                                <div className="password mb-3">
                                    <label className="" htmlFor="signin-password">Mobile</label>
                                    <PhoneInput
                                        international
                                        defaultCountry='IN'
                                        id="signin-password"
                                        name="user_mobile"
                                        type="text"
                                        className=" signin-password log_input"
                                        placeholder=""
                                        required
                                        value={userMobile}
                                        onChange={(value) => setuserMobile(value)}
                                    />

                                </div> <br />
                                <div className="text-center">
                                    <Button type="submit" className="app-btn-primarys w-100 theme-btn mx-auto" disabled={isSubmitting}>
                                        Log In
                                    </Button>
                                </div>
                                <Link to='/signin' className=''> or sign up?</Link>
                            </form>

                            <div className="auth-option text-center pt-5">
                                <Button variant='secondary' ><Link to='/' className='text-white'> Home</Link></Button>

                            </div>
                        </div>
                    </div>

                    <footer className="app-auth-footer">
                        <div className="container text-center py-3">
                            <small className="copyright">
                                Designed with <span className="sr-only">love</span>
                                <i className="fas fa-heart" style={{ color: '#fb866a' }}></i> by{' '}
                                <a className="app-link" href="https://codriveit.com" target="_blank" rel="noopener noreferrer">
                                    Codriveit
                                </a> for developers
                            </small>
                        </div>
                    </footer>
                </div>
            </div>

            <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                <div className="auth-background-holder"></div>
            </div>
        </div>
    );
}

export default withSwal(Login);