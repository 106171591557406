import React, { useEffect, useState } from 'react'
import Header from './Common/Header'
import SideBar from './Common/SideBar'
import Footer from './Common/Footer'
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

function MyAppoinments() {
    const [Appointments, setAppointments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    const [FilterAppointments, setFilterAppointments] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [appointmentPerPage] = useState(10)
    const [showModal, setShowModal] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState(null)
    const [selectedAppointmentDetails, setSelectedAppointmentDetails] = useState([])
    const [lifeIncidents, setLifeIncidents] = useState([]); // State for life incidents
    const [loadingIncidents, setLoadingIncidents] = useState(false); // State for loading indicator
    const [marriageMatching, setmarriageMatching] = useState([]); // State for life incidents
    const [loadingmarriageMatching, setLoadingmarriageMatching] = useState(false); // State for loading indicator

    const userEmail = localStorage.getItem('userEmail');
    const fetchAppointments = () => {
        axios.get(`https://alpastrology.online/alpapi/User_api/users_appointments?user_email=${userEmail}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123'
            }
        }).then((response) => {
            if (response.data.status === 'Success') {
                console.log(response)
                setAppointments(response.data.data)

            } else {
                setAppointments(null);
            }
        }).catch((error) => {
            console.error(error)
        })
    }
    useEffect(() => {
        fetchAppointments()
    }, []);


    useEffect(() => {
        const filtered = Appointments.filter((Appointment) =>
            Appointment.apt_user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            Appointment.apt_user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            Appointment.apt_user_mobile.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilterAppointments(filtered);
        setCurrentPage(0)
    }, [searchTerm, Appointments])

    const indexOfLastAppointment = (currentPage + 1) * appointmentPerPage;
    const indexOfFirstAppointment = indexOfLastAppointment - appointmentPerPage;
    const currentAppointment = FilterAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment)


    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleView = (message) => {
        setSelectedAppointment(message);
        setSelectedAppointmentDetails(message.apt_services);
        setShowModal(true)
        fetchLifeIncidents(message.apt_id); // Fetch incidents for selected appointment
        fetchMarriageMatching(message.apt_id);

    }

    const fetchMarriageMatching = (appointmentId) => {
        setLoadingmarriageMatching(true); // Start loading
        const inceident = new FormData();
        inceident.append('apt_id', appointmentId);
        axios.post('https://alpastrology.online/alpapi/User_api/getMarriageMatching', inceident,

            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-api-key': 'alp@123'
                }
            }
        )
            .then((response) => {
                if (response.data.status === 'Success') {
                    setmarriageMatching(response.data.data);
                    // console.log('1212',response)
                } else {
                    setmarriageMatching([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setmarriageMatching([]);
            })
            .finally(() => {
                setLoadingmarriageMatching(false); // Stop loading
            });
    };
    const fetchLifeIncidents = (appointmentId) => {
        setLoadingIncidents(true); // Start loading
        const inceident = new FormData();
        inceident.append('apt_id', appointmentId);
        axios.post('https://alpastrology.online/alpapi/User_api/getIncident', inceident,

            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-api-key': 'alp@123'
                }
            }
        )
            .then((response) => {
                if (response.data.status === 'Success') {
                    setLifeIncidents(response.data.data);
                    // console.log('1212',response)
                } else {
                    setLifeIncidents([]);
                }
            })
            .catch((error) => {
                console.error(error);
                setLifeIncidents([]);
            })
            .finally(() => {
                setLoadingIncidents(false); // Stop loading
            });
    };



    return (
        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">

                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <div className="wl_header">
                            <h3>My Appointments</h3>
                        </div>
                        <div className="mb-3 col-lg-4">
                            <input
                                type="text"
                                className="form-control table_search"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Table striped bordered hover variant="light" responsive>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentAppointment.length > 0 ? (
                                    currentAppointment.map((Appointment, index) => {
                                        const msgDate = new Date(Appointment.apt_created_dt).toLocaleDateString('en-GB');
                                        return (
                                            <tr key={index}>
                                                <td>{indexOfFirstAppointment + index + 1}</td>
                                                <td>{Appointment.apt_user_name}</td>
                                                <td>{Appointment.apt_user_email}</td>
                                                <td>{Appointment.apt_user_mobile}</td>
                                                <td>{msgDate}</td>
                                                <td>
                                                    <Button variant={Appointment.apt_status == '' ? 'warning' : Appointment.apt_status ==0 ? 'primary' : 'success'} className='text-white'>
                                                        {Appointment.apt_status == '' ? 'Pending' : Appointment.apt_status == 0 ? 'Scheduled' : 'Completed'}
                                                    </Button></td>
                                                <td>
                                                    <Button onClick={() => handleView(Appointment)}>View</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={7}>No Data...</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(FilterAppointments.length / appointmentPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Message Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedAppointment && (
                        <>
                            <p><strong>Name:</strong> {selectedAppointment.apt_user_name}</p>
                            <p><strong>Email:</strong> {selectedAppointment.apt_user_email}</p>
                            <p><strong>Mobile:</strong> {selectedAppointment.apt_user_mobile}</p>
                            {/* <p><strong>Date of Birth:</strong> {selectedAppointment.apt_user_dob}</p> */}
                            <p><strong>Date of Birth:</strong> {new Date(selectedAppointment.apt_user_dob).toLocaleDateString('en-GB')}</p>
                            <p><strong>Time of Birth:</strong> {selectedAppointment.apt_user_tob}</p>
                            <p><strong>Place of Birth:</strong> {selectedAppointment.apt_user_pob}</p>
                            <p><strong>Appointment Date:</strong> {new Date(selectedAppointment.apt_date).toLocaleDateString('en-GB')}</p>
                            <p><strong>Appointment Time:</strong> {selectedAppointment.apt_time}</p>
                            <p><strong>Services:</strong> {selectedAppointment.apt_services}</p>
                            <p><strong>Mode:</strong> {selectedAppointment.apt_mode}</p>
                            <p><strong>Status:</strong>
                                <Button variant={selectedAppointment.apt_status == '' ? 'info' : selectedAppointment.apt_status == 0 ? 'primary' : 'success'} className='text-white'>
                                    {selectedAppointment.apt_status == '' ? 'Pending' : selectedAppointment.apt_status == 0 ? 'Scheduled' : 'Completed'}
                                </Button>
                            </p>
                            <hr />
                            <h5>Scheduled Details</h5>
                            <p><strong>Date:</strong> {selectedAppointment.scheduled_date}</p>
                            <p><strong>Time:</strong> {selectedAppointment.scheduled_time}</p>
                            <p><strong>Mode:</strong> {selectedAppointment.scheduled_mode}</p>
                            <hr />
                        </>
                    )}

                    <h5>Life Incidents</h5>
                    {loadingIncidents ? (
                        <p>Loading...</p>
                    ) : (
                        <ul>
                            {lifeIncidents.length > 0 ? (
                                lifeIncidents.map((incident, idx) => (
                                    <li key={idx}>
                                        <strong>Date:</strong> {new Date(incident.incident_date).toLocaleDateString('en-GB')}<br />
                                        <strong>Incident:</strong> {incident.incident}
                                    </li>
                                ))
                            ) : (
                                <p>No incidents found.</p>
                            )}
                        </ul>
                    )}


                    {
                        selectedAppointmentDetails.includes("ALP Marriage Matching") && (
                            <>
                                <h5>Marriage Matching</h5>
                                {loadingmarriageMatching ? (
                                    <p>Loading...</p>
                                ) : (
                                    <ul>
                                        {marriageMatching.length > 0 ? (
                                            marriageMatching.map((incident, idx) => (
                                                <li key={idx}>

                                                    <table className='table'>
                                                        <tr>
                                                            <th>Bride Name</th>
                                                            <td>{incident.bride_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Bride Date of Birth</th>
                                                            <td>{new Date(incident.bride_dob).toLocaleDateString('en-GB')}</td>

                                                        </tr>
                                                        <tr>
                                                            <th>Bride Place of Birth</th>
                                                            <td>{incident.bride_pob}</td>

                                                        </tr>
                                                        <tr>
                                                            <th>Bride Time of Birth</th>
                                                            <td>{incident.bride_tob}</td>

                                                        </tr>
                                                    </table>
                                                    <table className='table'>
                                                        <tr>
                                                            <th>Groom Name</th>
                                                            <td>{incident.groom_name}</td>

                                                        </tr>
                                                        <tr>
                                                            <th>Groom Date of Birth</th>
                                                            <td>{new Date(incident.groom_dob).toLocaleDateString('en-GB')}</td>

                                                        </tr>
                                                        <tr>
                                                            <th>Groom Place of Birth</th>
                                                            <td>{incident.groom_pob}</td>

                                                        </tr>
                                                        <tr>
                                                            <th>Groom Time of Birth</th>
                                                            <td>{incident.groom_tob}</td>

                                                        </tr>
                                                    </table>
                                                </li>
                                            ))
                                        ) : (
                                            <p>No incidents found.</p>
                                        )}
                                    </ul>
                                )}
                            </>
                        )
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MyAppoinments