import React, { useState, useEffect } from 'react';
import Appbar from './AppBar';
import Footer from './Footer';
import Chatbot from './Chatbot';
import { Container, Carousel, Form } from 'react-bootstrap';
import axios from 'axios';
const Panchangam = () => {
    const [panchangamData, setPanchangamData] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Fetch the Panchangam data from API
        axios
            .post('https://alpastrology.online/alpapi/User_api/panchangamData', {}, {
                headers: {
                    'x-api-key': 'alp@123',
                },
            })
            .then((response) => {
                if (response.data.status === 'Success') {
                    setPanchangamData(response.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching Panchangam data:', error);
            });
    }, []);

    // Handle date change
    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    // Ensure selected date and current date match panch_date
    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
    };

    // Update the active index when selectedDate or currentDate changes
    useEffect(() => {
        const targetDate = selectedDate || currentDate;
        const index = panchangamData.findIndex((item) => formatDate(item.panch_date) === targetDate);

        if (index !== -1) {
            setActiveIndex(index);
        } else {
            setActiveIndex(0); // Default to the first slide if no match is found
        }
    }, [selectedDate, panchangamData, currentDate]);

    return (
        <>
            <Appbar />

            <Container className="my-4">
                <h2 className="text-center fw-bold">Daily Panchangam</h2><br />
                {/* Date Picker */}
                <Form.Group>
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                        type="date"
                        value={selectedDate || currentDate} // Use selectedDate if available, otherwise use currentDate
                        onChange={handleDateChange}
                        className='col-lg-2'
                    />
                </Form.Group>

                <Carousel
                    activeIndex={activeIndex} // Set the active index to control the current slide
                    onSelect={(selectedIndex) => setActiveIndex(selectedIndex)} // Set the selected index when navigating
                    interval={null} // Disable auto-scrolling (interval is set to null)
                    // style={{ height: '700px' }} // Set the height for the carousel
                    className="my-3"
                >
                    {panchangamData.map((item, index) => (
                        <Carousel.Item key={index}>
                            <img
                                src={item.image_path}
                                alt={`Panchangam for ${item.panch_date}`}
                                className="d-block w-100"
                                style={{ height: '500px', objectFit: 'contain' }} // Ensure image fits the carousel size
                            />
                            <Carousel.Caption>
                                {/* <h3 className="fw-bold text-dark">{formatDate(item.panch_date)}</h3> */}
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>

            <Footer />
            <Chatbot />
        </>
    );
};

export default Panchangam;
