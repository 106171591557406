import React, { useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Chatbot from './Components/Chatbot'
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import MyProfile from './Components/MyProfile';
import MyMessage from './Components/MyMessage';
import MyAppoinments from './Components/MyAppoinments';
import MakeAppt from './Components/MakeAppt';
import Classes from './Components/Classes';
import MakeMessage from './Components/MakeMessage';
import MyComponent from './Components/rough';
import Courses from './Components/WebCourses';
import HomePage from './Components/HomePage';
import Contact from './Components/Contact';
import Books from './Components/Books';
import SignupForm from './Components/SignupForm';
import DetailsForm from './Components/DetailsForm';
// import Sweet from './Components/sweet';
import Panchangam from './Components/Panchangam';

function App() {
    // useEffect(() => {
    //     // Disable right-click context menu
    //     const disableRightClick = (e) => {
    //       e.preventDefault();
    //       // alert('Right-click is disabled!');
    //     };
    
    //     // Disable specific key combinations
    //     const disableDevTools = (e) => {
    //       // Disable F12
    //       if (e.key === 'F12' || e.keyCode === 123) {
    //         e.preventDefault();
    //         // alert('Developer tools are disabled!');
    //       }
    
    //       // Disable Ctrl+Shift+I
    //       if (e.ctrlKey && e.shiftKey && e.key === 'I') {
    //         e.preventDefault();
    //         // alert('Developer tools are disabled!');
    //       }
    
    //       // Disable Ctrl+Shift+C
    //       if (e.ctrlKey && e.shiftKey && e.key === 'C') {
    //         e.preventDefault();
    //         // alert('Inspect element is disabled!');
    //       }
    
    //       // Disable Ctrl+U (view source)
    //       if (e.ctrlKey && e.key === 'u') {
    //         e.preventDefault();
    //         // alert('View source is disabled!');
    //       }
    //     };
    
    //     // Attach event listeners
    //     document.addEventListener('contextmenu', disableRightClick);
    //     document.addEventListener('keydown', disableDevTools);
    
    //     // Cleanup on unmount
    //     return () => {
    //       document.removeEventListener('contextmenu', disableRightClick);
    //       document.removeEventListener('keydown', disableDevTools);
    //     };
    //   }, []);
    return (
        <>
            <Router>
                <Routes>
                    {/* <Route path='/' element={<Chatbot />}/> */}
                    <Route path='/' element={<HomePage />} />
                    <Route path='/LoginScreen' element={<Login />} />
                    <Route path='/Dashboard' element={<Dashboard />} />
                    <Route path='/MyProfile' element={<MyProfile />} />
                    <Route path='/Mymessage' element={<MyMessage />} />
                    <Route path='/MyAppointments' element={<MyAppoinments />} />
                    <Route path='/MakeAppointment' element={<MakeAppt />} />
                    <Route path='/Classes' element={<Classes />} />
                    <Route path='/MakeMessage' element={<MakeMessage />} />
                    <Route path='/Course' element={<Courses />} />
                    {/* <Route path='/test' element={<Sweet />} /> */}
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/Books' element={<Books />} />
                    <Route path='/signin' element={<SignupForm />} />
                    <Route path='/Panchangam' element={<Panchangam />} />
                </Routes>
            </Router>
        </>
    )
}
export default App;