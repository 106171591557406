import React from "react";
import { Container, Row, Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Appbar = () => {
  return (
    <Container fluid>
      <Row className="header">
        <Navbar expand="lg" className="header_nav" sticky="top">
          <Container>
            <Navbar.Brand href="#home">
              <img src="/assets/logo.png" alt="Logo" width="100px" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="fw-bold">
                <Nav.Link as="div">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "text-white m-1 active-link" : "text-white m-1"
                    }
                  >
                    Home
                  </NavLink>
                </Nav.Link>
                <Nav.Link as="div">
                  <NavLink
                    to="/Course"
                    className={({ isActive }) =>
                      isActive ? "text-white m-1 active-link" : "text-white m-1"
                    }
                  >
                    Course
                  </NavLink>
                </Nav.Link>
                <Nav.Link as="div">
                  <NavLink
                    to="/Books"
                    className={({ isActive }) =>
                      isActive ? "text-white m-1 active-link" : "text-white m-1"
                    }
                  >
                    Books
                  </NavLink>
                </Nav.Link>
                <Nav.Link as="div">
                  <NavLink
                    to="/Panchangam"
                    className={({ isActive }) =>
                      isActive ? "text-white m-1 active-link" : "text-white m-1"
                    }
                  >
                    Panchangam
                  </NavLink>
                </Nav.Link>
                <Nav.Link as="div">
                  <NavLink
                    to="/loginscreen"
                    className={({ isActive }) =>
                      isActive ? "text-white m-1 active-link" : "text-white m-1"
                    }
                  >
                    Login
                  </NavLink>
                </Nav.Link>
                <Nav.Link as="div">
                  <NavLink
                    to="/Contact"
                    className={({ isActive }) =>
                      isActive ? "text-white m-1 active-link" : "text-white m-1"
                    }
                  >
                    Contact
                  </NavLink>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
    </Container>
  );
};

export default Appbar;
