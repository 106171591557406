import React, { useEffect, useState } from 'react';
import '../css/DetailsForm.css';
import axios from 'axios';
import { requestPermission, onMessageListener } from "../firebase/firebase";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const DetailsForm = ({ triggerNextStep }) => {

  const [username, setUserName] = useState()
  const [userMobile, setUserMobile] = useState()
  const [userDob, setUserDob] = useState()
  const [userTob, setUserTob] = useState()
  const [userPob, setUserPob] = useState()
  const [userEmail, setUserEmail] = useState()
  const [userReq, setUserReq] = useState()
  const [chat_type] = useState('Message Consultation')
 const [isSubmitting, setIsSubmitting] = useState(false);
  const [deviceToken, setDeviceToken] = useState(null);
  const [emailError, setEmailError] = useState(false)
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|online|org|net|edu|gov|info|io|co|in)$/;
    if (emailRegex.test(email)) {
      setUserEmail(email);
      setEmailError(false); // No error

      return true;  // Email

    } else {
      setEmailError(true); // Show error
      return false;  // Email

    }
  }

  useEffect(() => {
    const MAX_RETRIES = 15;
    let retryCount = 0;

    const fetchToken = () => {
      requestPermission()
        .then((token) => {
          if (token) {
            setDeviceToken(token);
            localStorage.setItem("deviceToken", token);
            console.log("FCM Token successfully retrieved:", token);
          } else if (retryCount < MAX_RETRIES) {
            retryCount++;
            console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
            setTimeout(fetchToken, 1000);
          } else {
            console.error("Failed to fetch device token after retries.");
          }
        })
        .catch((err) => {
          console.error("Error retrieving FCM Token:", err);
          if (retryCount < MAX_RETRIES) {
            retryCount++;
            console.log(`Retrying to fetch token... Attempt: ${retryCount}`);
            setTimeout(fetchToken, 1000);
          }
        });
    };

    fetchToken();

    onMessageListener()
      .then((payload) => {
        console.log("Message received in foreground:", payload);
        new Notification(payload.notification.title, {
          body: payload.notification.body,
        });
      })
      .catch((err) => {
        console.error("Error listening to foreground messages:", err);
      });
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(userEmail);
        if (!isValidEmail) {
            setIsSubmitting(false)
            return;
        }
        setIsSubmitting(true)
    const form = new FormData();
    form.append('user_name', username);
    form.append('user_mob', userMobile);
    form.append('user_dob', userDob);
    form.append('user_tob', userTob);
    form.append('user_pob', userPob);
    form.append('user_email', userEmail);
    form.append('user_req_type', userReq);
    form.append('user_device_token', deviceToken);
    form.append('chat_type', chat_type);

    try {
      const response = await fetch(
        'https://alpastrology.online/alpapi/User_api/users_reg',
        {
          method: 'POST',
          headers: {
            'x-api-key': 'alp@123'
          },
          body: form
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log('Response from server:', data);

        const notifData = new FormData();
        notifData.append('title', 'ALP Online');
        notifData.append('message', `${username} Sent a new Message request`);

        try {
          const notifResponse = await axios.post(
            'https://alpastrology.online/alpapi/User_api/notification_send',
            notifData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123',
              },
            }
          );

          console.log('Notification sent successfully:', notifResponse.data);
        } catch (notifError) {
          console.error('Error sending notification:', notifError.response || notifError.message);
        }

        triggerNextStep();
      } else {
        console.error('Error submitting the details form:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <form className="details-form" onSubmit={handleSubmit}>
      <h5 className="fw-bold pb-2">Enter Your Details</h5>

      <div>
        <label>Name:</label>
        <input
          type="text"
          name="user_name"
          value={username}
          onChange={(e) => setUserName(e.target.value)}
          required
        />
      </div>

      <div>
        <label>Mobile:</label>
        <PhoneInput
          defaultCountry='IN'
          international
          type="text"
          name="user_mob"
          value={userMobile}
          onChange={(value) => setUserMobile(value)}
          required
        />
      </div>

      <div>
        <label>Date of Birth:</label>
        <input
          type="date"
          name="user_dob"
          value={userDob}
          onChange={(e) => setUserDob(e.target.value)}
          required
        />
      </div>

      <div>
        <label>Time of Birth:</label>
        <input
          type="time"
          name="user_tob"
          value={userTob}
          onChange={(e) => setUserTob(e.target.value)}
          required
          step={1}

        />
      </div>

      <div>
        <label>Place of Birth:</label>
        <input
          type="text"
          name="user_pob"
          value={userPob}
          onChange={(e) => setUserPob(e.target.value)}
          required
        />
      </div>

      <div>
        <label>Email:</label>
        <input
          type="email"
          name="user_email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          required
        />
        {emailError && (
          <small className='text-danger'>Please Enter the valid Email</small>
        )}
      </div>

      <div>
        <label>Select Service</label>
        <select
          name="user_req"
          value={userReq}
          onChange={(e) => setUserReq(e.target.value)}
          required
        >
          <option value="">Select</option>
          <option value="ALP Astrology">ALP Astrology</option>
          <option value="ALP Vastu Shastra">ALP Vastu Shastra</option>
          <option value="Alp Numerology">Alp Numerology</option>
          <option value="ALP Prasanam">ALP Prasanam</option>
          <option value="ALP Gemology">ALP Gemology</option>
          <option value="ALP Marriage Matching">ALP Marriage Matching</option>
        </select>
      </div>


      <button type="submit" className="botBtn" disabled={isSubmitting}>Submit</button>
    </form>
  );
};

export default DetailsForm;
